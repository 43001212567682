let errorCount = 0;
/**
 * When there's only one statement, you can drop the {} and the return
 * x => y is equivalent to x => { return y; }
 * @param {string} path
 */
function checkImageOnLoad(path, loadProgress = null) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = path;
    img.onload = () => {
      if (loadProgress !== null && loadProgress !== undefined) {
        loadProgress();
      }
      resolve(img);
    };
    img.onerror = () => {
      if (errorCount === 1) {
        reject('img404');
      } else {
        if (loadProgress !== null && loadProgress !== undefined) {
          loadProgress();
        }
        resolve(new Image().src = '');
      }
      errorCount++;
    };
  });
}

/**
   * Load image from array of image path
   * @param {string[]} paths
   */
export function loadImg(paths, loadProgress) {
  errorCount = 0;
  return Promise.all(paths.map((path) => checkImageOnLoad(path, loadProgress)));
}

/**
 * Load image from array of image path
  * @param {string[]} paths
  */
export async function optimisticLoadImg(paths) {
  let results = [];
  for (let path of paths) {
    const image = await checkImageOnLoadOptimistic(path);
    results.push(image);
  }
  return Promise.resolve(results);
}

/**
 * When there's only one statement, you can drop the {} and the return
 * x => y is equivalent to x => { return y; }
 * @param {string} path
 */
function checkImageOnLoadOptimistic(path) {
  // If have error more than 10 then that mean the picture is undefined
  // else maybe have a problem while fetching
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject('img404');
    };
    img.src = path;
  });
}
