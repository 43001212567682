var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wheelThreeSixtyWrapper",class:[
    'threesixty__wheel',
    _vm.loadingProgress < 100 ? 'loading' : null,
    _vm.isNot360 ? 'not-360': null
  ]},[_c('div',{staticClass:"wheel__wrapper"},[(_vm.error.loadWheel)?_c('p',{staticClass:"text-center error-msg"},[_vm._v(" Load wheel error. ")]):[_c('div',{staticClass:"wheel-img__container"},[_c('transition',{attrs:{"name":"fade-loading-car360"}},[_c('circular-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingProgress < 100),expression:"loadingProgress < 100"}],ref:"circularLoaderRef",staticClass:"circular-loader",staticStyle:{"line-height":"0"},attrs:{"progress":_vm.loadingProgress,"size":50,"fill":_vm.circularLoaderFill,"animation-start-value":0.0,"start-angle":-1.6,"thickness":6,"show-percent":true,"insert-mode":"append","empty-fill":"rgba(0, 0, 0, .1)"}})],1),_c('transition',{attrs:{"name":"fade-car360"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingProgress === 100),expression:"loadingProgress === 100"}],key:_vm.src,class:['wheel-img-wrapper', _vm.isDragging || _vm.isNot360 || _vm.oneFrame ? null : 'show-rotate-bg']},[(_vm.isNot360 || _vm.oneFrame)?_c('img',{ref:"wheelThreeSixtyImage"}):_c('img',{ref:"carThreeSixtyImage",attrs:{"draggable":"false"}}),_c('span',{staticClass:"rotate-bg",style:({
                backgroundImage: `url(${require('@/assets/images/car-rotate-small.svg')})`
              })}),(!_vm.isNot360)?[_c('button',{staticClass:"ro-left",on:{"click":_vm.onClickRotateLeft}}),_c('button',{staticClass:"ro-right",on:{"click":_vm.onClickRotateRight}})]:_vm._e()],2)])],1)]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }