<template>
  <div
    ref="wheelThreeSixtyWrapper"
    :class="[
      'threesixty__wheel',
      loadingProgress < 100 ? 'loading' : null,
      isNot360 ? 'not-360': null
    ]"
  >
    <div class="wheel__wrapper">
      <p
        v-if="error.loadWheel"
        class="text-center error-msg"
      >
        Load wheel error.
      </p>
      <template v-else>
        <div class="wheel-img__container">
          <transition name="fade-loading-car360">
            <circular-loader
              v-show="loadingProgress < 100"
              ref="circularLoaderRef"
              :progress="loadingProgress"
              :size="50"
              :fill="circularLoaderFill"
              :animation-start-value="0.0"
              :start-angle="-1.6"
              :thickness="6"
              :show-percent="true"
              style="line-height: 0;"
              class="circular-loader"
              insert-mode="append"
              empty-fill="rgba(0, 0, 0, .1)"
            />
          </transition>
          <transition name="fade-car360">
            <div
              v-show="loadingProgress === 100"
              :key="src"
              :class="['wheel-img-wrapper', isDragging || isNot360 || oneFrame ? null : 'show-rotate-bg']"
            >
              <img
                v-if="isNot360 || oneFrame"
                ref="wheelThreeSixtyImage"
              >
              <img
                v-else
                ref="carThreeSixtyImage"
                draggable="false"
              >
              <span
                class="rotate-bg"
                :style="{
                  backgroundImage: `url(${require('@/assets/images/car-rotate-small.svg')})`
                }"
              />
              <template v-if="!isNot360">
                <button
                  class="ro-left"
                  @click="onClickRotateLeft"
                />
                <button
                  class="ro-right"
                  @click="onClickRotateRight"
                />
              </template>
            </div>
          </transition>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/styles/components/threesixty__wheel.scss';
</style>

<script>
import { loadImg } from '@/utils/preloadImage';
import VueCircle from 'vue2-circle-progress';
import { getTextAfterWord, getTextBeforeWord, isNot360Wheel } from '@/utils';
import { primary, primaryDark } from '@/configs/themeColor';

export default {
  components: {
    'circular-loader': VueCircle,
  },
  props: {
    size: {
      type: Number,
      default: 400,
    },
    src: {
      type: String,
      default: '',
    },
    frames: {
      type: Array,
      default: () => [],
    },
    oneFrameSrc: {
      type: String,
      default: '',
    },
    oneFrameSize: {
      type: String,
      default: '',
    },
    threshold: {
      type: Number,
      default: 0.35,
    },
    zoomin: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    wheelLoading: {
      type: Boolean,
      default: false,
    },
    oneFrame: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadWheelFrame: false,
      isDragging: true,
      wheelThreesixtyFrames: [],
      wheelThreesixtyImages: [],
      mouseX: 0,
      oldMouseX: 0,
      currentFrame: 0,
      error: {
        loadWheel: false,
      },
      wheelThreeSixtyCanvas: null,
      wheelThreeSixtyContext: null,
      filteredSize: 200,

      loadingProgress: 0,
      loadedVal: 0,
      loadingTimer: null,
    };
  },
  computed: {
    circularLoaderFill() {
      return { gradient: [primary, primaryDark] };
    },
    exactCurrentFrame() {
      return Math.abs(Math.floor(this.currentFrame % this.wheelFrameCnt));
    },
    wheelId() {
      const textAfterUniwheelsStatic = getTextAfterWord('https://cdn.jfnet.de/', this.src);
      const textBeforeJpg = getTextBeforeWord('.jpg', textAfterUniwheelsStatic);
      return textAfterUniwheelsStatic.substring(0, textBeforeJpg.lastIndexOf('_'));
    },
    wheelFrameCnt() {
      if (this.isNot360) {
        return 1;
      }
      return this.frames.length;
    },
    prefixImagePath() {
      return 'https://cdn.jfnet.de';
    },
    isWheelAndCarLoading() {
      return this.isLoadWheelFrame || this.wheelLoading;
    },
    isLoading() {
      return this.isLoadWheelFrame || this.isWheelAndCarLoading;
    },
    adjustedThreshold() {
      return this.threshold;
    },
    isNot360() {
      if (this.frames) {
        return this.frames.length === 0;
      }
      return true;
    },
    isItwheels() {
      return this.src.includes('itwheel');
    },
  },
  watch: {
    isError(val) {
      if (val) {
        this.isLoadWheelFrame = false;
      }
      this.error.loadWheel = val;
    },
    size() {
      // this.setCanvasSize();
      setTimeout(() => {
        this.setCarFrame();
        // this.drawImageScaled(this.wheelThreesixtyImages[this.exactCurrentFrame]);
      }, 300);
    },
  },
  async created() {
    this.isLoadWheelFrame = true;
    this.isFirstTime = true;
  },
  async mounted() {
    // if (!this.isNot360) {
    //   // this.wheelThreeSixtyCanvas = this.$refs.threesixtyWheelCanvas;
    //   // this.wheelThreeSixtyContext = this.$refs.threesixtyWheelCanvas.getContext('2d');
    //   // this.setCanvasSize();
    //   this.setCarFrame();
    // }
    await this.loadWheel();
  },
  destroyed() {
    document.removeEventListener('touchend', this.onStopDrag, {
      passive: true,
    });
    document.removeEventListener('mouseup', this.onStopDrag);
    document.removeEventListener('touchmove', this.onDrag);
    document.removeEventListener('mousemove', this.onDrag);
  },
  methods: {
    async loadWheel() {
      this.loadedVal = 0;
      this.loadingProgress = 0;
      clearInterval(this.loadingTimer);
      this.isLoadWheelFrame = true;
      this.$emit('on-load', true);
      this.error.loadWheel = false;

      if (this.isNot360 || this.oneFrame) {
        const oneFrame = this.getWheelImageSize(this.oneFrameSrc, this.oneFrameSize);
        await loadImg([oneFrame], this.onLoadFrameProgress);
        this.$refs.wheelThreeSixtyImage.src = oneFrame;
        this.isLoadWheelFrame = false;
        this.isFirstTime = false;
        this.$emit('on-load', false);
        document.removeEventListener('touchend', this.onStopDrag, {
          passive: true,
        });
        document.removeEventListener('mouseup', this.onStopDrag);
        document.removeEventListener('touchmove', this.onDrag);
        document.removeEventListener('mousemove', this.onDrag);
        return;
      }

      this.wheelThreesixtyFrames = this.getThreeSixtyImages(this.wheelId);
      await this.initThreeSixty();
    },
    loadWheelFrames(framePaths) {
      this.loadingTimer = setInterval(() => {
        this.countLoadFrameProgress();
      }, 1000);
      // Preload car threesixty frames
      return loadImg(framePaths, this.onLoadFrameProgress)
        .then((results) => {
          this.wheelThreesixtyImages = results;
          clearInterval(this.loadingTimer);

          setTimeout(() => {
            for (let i = 0; i < this.wheelFrameCnt; i++) {
              setTimeout((frame) => {
                if (this.isItwheels) {
                  this.$refs.carThreeSixtyImage.src =  this.wheelThreesixtyImages[6].src;
                } else {
                  this.$refs.carThreeSixtyImage.src =  this.wheelThreesixtyImages[frame].src;
                  if (i === this.wheelFrameCnt - 1) {
                    setTimeout(() => {
                      this.$refs.carThreeSixtyImage.src =  this.wheelThreesixtyImages[0].src;
                    }, i * 25);
                  }
                }
              }, i * 50, i); // we're passing x
            }
          }, 2000);
        })
        .catch((msg) => {
          if (msg === 'img404') {
            this.isLoadWheelFrame = false;
            this.error.loadWheel = true;
          }
        });
    },
    onLoadFrameProgress() {
      this.loadedVal++;
      this.loadingProgress = (this.loadedVal * 100) / this.wheelFrameCnt;
      if (this.$refs.circularLoaderRef) {
        this.$refs.circularLoaderRef.updateProgress(this.loadingProgress);
      }
    },
    countLoadFrameProgress() {
      if (this.loadingProgress > 80) {
        this.loadingProgress += 0.5;
      } else if (this.loadingProgress > 100) {
        this.loadingProgress = 100;
        clearInterval(this.loadingTimer);
      } else {
        this.loadingProgress += 1;
      }

      if (this.$refs.circularLoaderRef) {
        this.$refs.circularLoaderRef.updateProgress(this.loadingProgress);
      }
    },
    getThreeSixtyImages(wheel) {
      let imagePaths = [];
      for(let angle = 1; angle <= this.wheelFrameCnt; angle+=1) {
        // car360/{id}-{frame}-{color}-{suspension}-{brand}/{resolution}-cc-t-xxxxxx
        imagePaths.push(`${this.prefixImagePath}/${wheel}_${angle}.jpg`);
      }
      return imagePaths;
    },

    // Three sixty events
    async initThreeSixty() {
      // Preload car threesixty frames
      return this.loadWheelFrames(this.wheelThreesixtyFrames)
        .then(() => {
          this.initListeners();
          this.isLoadWheelFrame = false;
          this.isDragging = false;
          this.isFirstTime = false;
          this.$emit('on-load', false);
        })
        .catch(() => {
          this.isLoadWheelFrame = false;
          this.error.loadWheel = true;
          this.$emit('is-loading', this.isLoadWheelFrame);
          this.$emit('is-error', this.error.loadWheel);
        });
    },
    initListeners() {
      this.$refs.wheelThreeSixtyWrapper.addEventListener('touchstart', this.initDrag, {
        passive: true,
      });
      this.$refs.wheelThreeSixtyWrapper.addEventListener('mousedown', this.initDrag);
    },
    initDrag(e) {
      if (!this.isTouchEvent(e)) {
        e.preventDefault();
      }

      document.addEventListener('touchmove', this.onDrag, {
        passive: true,
      });
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('touchend', this.onStopDrag);
      document.addEventListener('mouseup', this.onStopDrag);
    },
    onDrag(e) {
      this.isDragging = true;
      this.$refs.wheelThreeSixtyWrapper.style.cursor = 'grabbing';

      if (!this.isTouchEvent(e)) {
        e.preventDefault();
      }

      this.mouseX = (e.pageX !== null || e.pageX !== undefined) ? e.pageX : e.changedTouches[0].pageX;

      if (this.mouseX < this.oldMouseX) {
        this.onDragLeft();
      } else if (this.mouseX > this.oldMouseX) {
        this.onDragRight();
      }
      setTimeout(() => {
        this.oldMouseX = this.mouseX;
      }, 100);
    },
    onStopDrag(e) {
      this.isDragging = false;
      this.$refs.wheelThreeSixtyWrapper.style.cursor = 'grab';

      if (!this.isTouchEvent(e)) {
        e.preventDefault();
      }

      document.addEventListener('touchend', this.onStopDrag, {
        passive: true,
      });
      document.addEventListener('mouseup', this.onStopDrag);
      document.removeEventListener('touchmove', this.onDrag);
      document.removeEventListener('mousemove', this.onDrag);
    },
    onDragRight() {
      this.currentFrame += this.adjustedThreshold;
      this.setCarFrame();
      // this.drawImageScaled(this.wheelThreesixtyImages[this.exactCurrentFrame]);
    },
    onDragLeft() {
      if (this.currentFrame <= 0) {
        this.currentFrame = this.wheelFrameCnt - 1;
      } else {
        this.currentFrame -= this.adjustedThreshold;
      }
      this.setCarFrame();
      // this.drawImageScaled(this.wheelThreesixtyImages[this.exactCurrentFrame]);
    },
    onClickRotateLeft() {
      this.currentFrame += 1;
      this.setCarFrame();
      // this.drawImageScaled(this.wheelThreesixtyImages[this.exactCurrentFrame]);
    },
    onClickRotateRight() {
      if (this.currentFrame <= 0) {
        this.currentFrame = this.wheelFrameCnt - 1;
      } else {
        this.currentFrame -= 1;
      }
      this.setCarFrame();
      // this.drawImageScaled(this.wheelThreesixtyImages[this.exactCurrentFrame]);
    },
    isTouchEvent(e) {
      return e.touches;
    },
    setCanvasSize() {
      if (this.zoomin) {
        setTimeout(() => {
          this.wheelThreeSixtyCanvas.width = document.querySelector('.wheel-zoomin-modal').clientWidth * 0.65;
          this.wheelThreeSixtyCanvas.height = document.querySelector('.wheel-zoomin-modal').clientWidth * 0.65;
        }, 200);
      } else {
        setTimeout(() => {
          this.wheelThreeSixtyCanvas.width = this.size;
          this.wheelThreeSixtyCanvas.height = this.size;
        }, 200);
      }
    },
    drawImageScaled(img) {
      const canvas = this.wheelThreeSixtyCanvas;
      const hRatio = canvas.width  / img.width    ;
      const vRatio =  canvas.height / img.height  ;
      const ratio  = Math.min ( hRatio, vRatio );
      const centerShift_x = ( canvas.width - img.width*ratio ) / 2;
      const centerShift_y = ( canvas.height - img.height*ratio ) / 2;

      this.wheelThreeSixtyContext.clearRect(0,0,canvas.width, canvas.height);
      this.wheelThreeSixtyContext.drawImage(img, 0,0, img.width, img.height,
        centerShift_x,centerShift_y,img.width*ratio, img.height*ratio);
    },
    setCarFrame() {
      this.$refs.carThreeSixtyImage.src =  this.wheelThreesixtyImages[this.exactCurrentFrame].src;
    },
    resizeCanvas(canvas, newWidth, newHeight) {
      let ctx = canvas.getContext('2d');
      let buffer = document.createElement('canvas');
      buffer.width = ctx.canvas.width;
      buffer.height = ctx.canvas.height;
      let ctxBuf = buffer.getContext('2d');

      let scaleX = newWidth / ctx.canvas.width;
      let scaleY = newHeight / ctx.canvas.height;

      let scaler = Math.min(scaleX, scaleY);
      //see if target scale is less than half...
      if (scaler < 0.5) {
        //while loop in case target scale is less than quarter...
        while (scaler < 0.5) {
          ctxBuf.canvas.width = ctxBuf.canvas.width * 0.5;
          ctxBuf.canvas.height = ctxBuf.canvas.height * 0.5;
          ctxBuf.scale(0.5, 0.5);
          ctxBuf.drawImage(canvas, 0, 0);
          ctxBuf.setTransform(1, 0, 0, 1, 0, 0);
          ctx.canvas.width = ctxBuf.canvas.width;
          ctx.canvas.height = ctxBuf.canvas.height;
          ctx.drawImage(buffer, 0, 0);

          scaleX = newWidth / ctxBuf.canvas.width;
          scaleY = newHeight / ctxBuf.canvas.height;
          scaler = Math.min(scaleX, scaleY);
        }
        //only if the scaler is now larger than half, double target scale trick...
        if (scaler > 0.5) {
          scaleX *= 2.0;
          scaleY *= 2.0;
          ctxBuf.canvas.width = ctxBuf.canvas.width * scaleX;
          ctxBuf.canvas.height = ctxBuf.canvas.height * scaleY;
          ctxBuf.scale(scaleX, scaleY);
          ctxBuf.drawImage(canvas, 0, 0);
          ctxBuf.setTransform(1, 0, 0, 1, 0, 0);
          scaleX = 0.5;
          scaleY = 0.5;
        }
      } else
        ctxBuf.drawImage(canvas, 0, 0);

      //wrapping things up...
      ctx.canvas.width = newWidth;
      ctx.canvas.height = newHeight;
      ctx.scale(scaleX, scaleY);
      ctx.drawImage(buffer, 0, 0);
      ctx.setTransform(1, 0, 0, 1, 0, 0);
    },
    checkImageOnLoad(path) {
      // If have error more than 10 then that mean the picture is undefined
      // else maybe have a problem while fetching
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          resolve(img);
        };
        img.onerror = () => {
          reject('img404');
        };
        img.src = path;
      });
    },
    getWheelImageSize(image, size) {
      const pathSplits = image.split('/');
      const path = pathSplits.slice(0, pathSplits.length - 1).join('/');
      return `${path}/${size}-cc-xxxxxx.png`;
    },
  },
};
</script>